import React, {useState, useEffect} from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

const Resume = (props) => {
    const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
    const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;

        Animations.animations.fadeInScreen(props.id);
    };
    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const ResumeHeading = (props) => {
        return (
            <div className="resume-heading">
                <div className="resume-main-heading">
                    {/*
                    <div className="heading-bullet"></div>
*/}
                    <span>{props.heading ? props.heading : ""}</span>
                    {props.fromDate && props.toDate ? (
                        <div className="heading-date">
                            {props.fromDate + "-" + props.toDate}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="resume-sub-heading">
                    <span>{props.subHeading ? props.subHeading : ""}</span>
                </div>
                <div className="resume-heading-description">
                    <span>{props.description ? props.description : ""}</span>
                </div>
            </div>
        );
    };

    const resumeBullets = [
        {label: "Education", logoSrc: "education.svg"},
        {label: "Work History", logoSrc: "work-history.svg"},
        {label: "QA Skills", logoSrc: "qa.svg"},
        {label: "Programming Skills", logoSrc: "programming-skills.svg"},
        {label: "Projects", logoSrc: "projects.svg"},
    ];

    const programmingSkillsDetails = [
        {skill: "JavaScript", ratingPercentage: 85},
        {skill: "TypeScript", ratingPercentage: 85},
        {skill: "Java", ratingPercentage: 80},
        {skill: "Python", ratingPercentage: 80},
        {skill: "Node JS", ratingPercentage: 70},
        {skill: "React", ratingPercentage: 70},
        {skill: "HTML", ratingPercentage: 70},
        {skill: "CSS", ratingPercentage: 70},
    ];

    const projectsDetails = [
        {
            title: "Personal Portfolio Website",
            duration: {fromDate: "2020", toDate: "2021"},
            description:
                "A Personal Portfolio website to showcase all my details and projects at one place.",
            subHeading: "Technologies Used: React JS, Bootsrap",
        },
        {
            title: "Die Mobiliar Employee App UI Automation",
            duration: {fromDate: "2018", toDate: "2021"},
            description:
                "Sales, Claim, Inspection, XpertCenter, Car Expert",
            subHeading:
                "Technologies Used:  NodeJS, Javascript, Typescript, WebdriverIO.",
        },
        {
            title: "SteadFast Tech Insurance Web Manual Testing ",
            duration: {fromDate: "2016", toDate: "2018"},
            description:
                "",
            subHeading:
                "Tool Used: TestRail, Jira, Confluence, Robot Framework, Selenium.",
        },
    ];

    const resumeDetails = [
        <div className="resume-screen-container" key="education">
            <ResumeHeading
                heading={"Udemy, Youtube, Linkedin, Google, O'reilly"}
                subHeading={"ONLINE COURSES"}
                fromDate={"2013"}
                toDate={"2021"}
            />

            <ResumeHeading
                heading={"University of Economics Ho Chi Minh city"}
                subHeading={"BACHELOR OF BUSINESS INFORMATION SYSTEM"}
                fromDate={"2009"}
                toDate={"2013"}
            />

            <ResumeHeading
                heading={"ISTQB "}
                subHeading={"SOFTWARE TESTING"}
                fromDate={"2013"}
                toDate={"2013"}
            />
        </div>,

        <div className="resume-screen-container" key="work-experience">
            <div className="experience-container">
                <ResumeHeading
                    heading={"Die Mobiliar"}
                    subHeading={"Senior QA Engineer"}
                    fromDate={"2018"}
                    toDate={"Present"}
                />
                <div className="experience-description">
          <span className="resume-description-text">
            Currently working as QA engineer both manual and automation for Frontend and Backend applications.
          </span>
                </div>
                <div className="experience-description">
                    <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                    <span className="resume-description-text">Create all the test artifacts: test strategy, test plan, estimation, test cases, traceability matrix</span><br/>
                    <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                    <span className="resume-description-text">
                    Setup the allowed environment (localhost web and Postman) and execute the API testing: call API and validate the responses.{" "}
                    </span>
                    <br/>
                    <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                    <span className="resume-description-text">
            Setup the UI automation test framework from scratch using Appium, Typescript(compile to Javascript),
                        WebdriverIO, Jasmine test framework, Allure reporting, Slack notification with full information, such as, logging, screenshots for failed test cases that run on real devices and simulators
          </span>
                    <br/>
                </div>
            </div>
        </div>,
        /* QA SKILLS */
        <div className="resume-screen-container" key="qa">
            <ResumeHeading
                heading="Manual Testing"
            />
            <div className="experience-description">
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span
                    className="resume-description-text">Test management: Organization, Planning, Estimation, Report</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Test techniques: Black-box, White-box, Experience-based, ad-hoc, shift-left</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span
                    className="resume-description-text">Test level: unit, component, integration, system, acceptance</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Test type: functional, non-functional</span><br/>

            </div>
            <ResumeHeading
                heading="Automation Testing"
            />
            <div className="experience-description">
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Frontend: Selenium, Appium, WebdriverIO, Cypress, Espresso, Jasmine, Mocha, Fluent Page Object Modal Component Pattern, Protractor</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Backend: Karate, Postman</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Performance Test: Gatling</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">CI/CD: Jenkins, Github</span><br/>
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">Cloud: BrowserStack, AWS, Azure</span><br/>
            </div>
            <ResumeHeading
                heading="System Analysis and Design"
            />
            <div className="experience-description">
                <FontAwesomeIcon icon={faCheck} className={"resume-description-bullet"}></FontAwesomeIcon>
                <span className="resume-description-text">UML, DFD, Relational Database</span><br/>
            </div>
        </div>,

        /* PROGRAMMING SKILLS */
        <div
            className="resume-screen-container programming-skills-container"
            key="programming-skills"
        >
            {programmingSkillsDetails.map((skill, index) => (
                <div className="skill-parent" key={index}>
                    {/*
                    <div className="heading-bullet"></div>
*/}
                    <span>{skill.skill}</span>
                    <div className="skill-percentage">
                        <div
                            style={{width: skill.ratingPercentage + "%"}}
                            className="active-percentage-bar"
                        ></div>
                    </div>
                </div>
            ))}
        </div>,


        /* PROJECTS */
        <div className="resume-screen-container" key="projects">
            {projectsDetails.map((projectsDetails, index) => (
                <ResumeHeading
                    key={index}
                    heading={projectsDetails.title}
                    subHeading={projectsDetails.subHeading}
                    description={projectsDetails.description}
                    fromDate={projectsDetails.duration.fromDate}
                    toDate={projectsDetails.duration.toDate}
                />
            ))}
        </div>
    ];

    const handleCarousal = (index) => {
        let offsetHeight = 360;

        let newCarousalOffset = {
            style: {transform: "translateY(" + index * offsetHeight * -1 + "px)"},
        };

        setCarousalOffsetStyle(newCarousalOffset);
        setSelectedBulletIndex(index);
    };

    const getBullets = () => {
        return resumeBullets.map((bullet, index) => (
            <div
                onClick={() => handleCarousal(index)}
                className={
                    index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
                }
                key={index}
            >
                <img
                    className="bullet-logo"
                    src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
                    alt="B"
                />
                <span className="bullet-label">{bullet.label}</span>
            </div>
        ));
    };

    const getResumeScreens = () => {
        return (
            <div
                style={carousalOffsetStyle.style}
                className="resume-details-carousal"
            >
                {resumeDetails.map((ResumeDetail) => ResumeDetail)}
            </div>
        );
    };

    useEffect(() => {
        return () => {
            /* UNSUBSCRIBE THE SUBSCRIPTIONS */
            fadeInSubscription.unsubscribe();
        };
    }, [fadeInSubscription]);

    return (
        <div
            className="resume-container screen-container fade-in"
            id={props.id || ""}
        >
            <div className="resume-content">
                <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"}/>
                <div className="resume-card">
                    <div className="resume-bullets">
                        <div className="bullet-container">
                            <div className="bullet-icons"></div>
                            <div className="bullets">{getBullets()}</div>
                        </div>
                    </div>

                    <div className="resume-bullet-details">{getResumeScreens()}</div>
                </div>
            </div>
        </div>
    );
};

export default Resume;
